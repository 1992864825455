import React from "react"
import HeadSection from "../components/new-design/HeadSection"
import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"
export default function terms({ location }) {
  return (
    <Layout>
      <Seo
        title="Terms & Conditions"
        pathname={location.pathname}
        description="Ibiza High Life acts solely as an intermediary between owners, agents
        and rental clients, being responsible only for accurate information
        regarding available properties, facilities and precise location."
      />
      <HeadSection title="Terms & Conditions" />
      <Contents />
    </Layout>
  )
}

const Contents = () => {
  return (
    <div className="p-2 lg:p-8 container mx-auto py-12">
      {/* <h1 className="pb-[30px]">Terms & Conditions</h1> */}
      <div className="grid gap-2">
        <Title title="1) Payment Terms" />
        <Content>
          Standard terms of payment for bookings are 50% of the total amount 48
          hours after receiving your contract, with the remaining 50% to be paid
          6 weeks before your arrival.
        </Content>
        <Title title="2) Damage Deposit" />

        <Content>
          Your damage deposit is only due 1 week before your check in date. At
          check out the villa will be inspected with the house manager to check
          that there are no damages. If nothing has been damaged the funds will
          be wired back to the same account you made the payment from usually 72
          hours after your check out. If there has been any damage the owner
          will get quotes for repairs and provide invoices for the amount in
          question. This will be deducted from your deposit before the balance
          is returned to your account.
        </Content>
        <Title title="3) Covid Policy" />
        <Content>
          The individual covid policies of each villa will be stipulated in your
          contract and can be provided for you prior to your booking to ensure
          you are happy to confirm.
        </Content>
        <Title title="4) Arrival and Departure" />

        <Content>
          The accommodation will be available from 16:00 hours on the day of
          arrival, unless otherwise specified.
          <br />
          The accommodation should be vacated at 10:00 hours on the day of
          departure, unless otherwise specified.
        </Content>
        <Title title="5) Modifications" />

        <Content>
          Modifications to the booking contract and exceptions to the present
          general conditions of the reservation will only take effect with the
          written agreement between the rental client and Book Ibiza If any such
          modifications result in an increase or decrease in the cost of the
          rental, both parties will be required to agree in writing to a
          modification in rental price.
        </Content>
        <Title title="6) Arrival Procedure and Customer Services Department" />
        <Content>
          Prior to your arrival, normally at the time of signing the contract
          you would send your flight details and arrival time. We can then be
          ready and waiting for you at the villa. Check in times are usually
          from 4pm. In the case that your arrival is earlier or late at night we
          will do our best to facilitate this for you. Early check-ins cannot
          always be guaranteed and depend on the previous booking at the
          particular property. Office Hours: 10:00 to 20:00 Telephone: Book
          Ibiza
        </Content>
        <Title title="7) General Procedure for Arrivals" />
        <Content>
          As soon as you touch down at Ibiza airport, please call your agent to
          inform them of your arrival. We will have provided the number of your
          check in agent 1-2 days before your arrival along with a pin of the
          location of the meeting place. <br />
          For all clients who arrive at night and have the right to check-in to
          the accommodation immediately, we request that they inform us as soon
          as possible so that we can give them the arrival information such as;
          directions to the property, instructions for key collection. <br />
          On the day of departure from the property you should check-out no
          later than 10.00 am. You should hand the keys in to our staff at
          departure unless otherwise specified. It is very important that you
          adhere to this condition of rental as the efficient functioning of our
          system depends on it.
        </Content>
        <Title title="8) Car hire" />
        <Content>
          Almost all of our rental properties are situated in rural or
          residential areas, very few are served by public transport and taxis
          can be limited in supply and prove difficult to direct to private
          villas which are not situated in major tourist resorts. For this
          reason we recommend booking car hire in advance and with a well known
          company that you can trust, to prevent nasty surprises. Book Ibiza can
          provide car hire directly on arrival at Ibiza airport. The car will be
          ready for collection without the wait.
        </Content>
        <Title title="9) Music, parties and noise" />
        <Content>
          It is totally unacceptable to disturb neighbours with music or noise.
          Music and sounds between 10.00 pm and 9.00 am should be no more than
          45 decibels. Parties are strictly prohibited in all properties and
          should the police be called to the home the client would be held fully
          responsible for any consequences and legal action against the
          property. Gatherings and dinners for more than 20 people greater than
          advertised sleeping occupancy are also prohibited except in the case
          of an official event using contracted caterers, waiters and security
          staff. Any of these actions can result in the cancellation of the
          contract and eviction from the property, with the loss of the total
          cost of the booking and the Security/Damage Deposit with no
          possibility of reimbursement.
        </Content>
        <Title title="10) Cleaning" />
        <Content>
          On the day of departure the property should be left in the same
          condition as on arrival. It is important to ensure that the kitchen,
          barbecue and all utensils are left completely clean and in good
          condition. Failure to do so will incur a deduction from your deposit
          of at least 80€. This could be more depending on the condition of the
          property when checked on departure. Extra cleaning can be contracted
          for the duration of your stay.We request that you inform us before
          your arrival if you would like to use this service.
        </Content>
        <Title title="11) Keys" />
        <Content>
          In the case of lost keys (a failure to hand in the same number of keys
          as supplied on arrival). We are obligated to deduct a minimum of 150€
          for a lock change and services of maintenance.
        </Content>
        <Title title="12) Furniture and Fittings" />
        <Content>
          It is strictly prohibited to change the position of the furnishings in
          the property or to carry out any other modifications
        </Content>
        <Title title="13) Capacity of the property and number of occupants" />
        <Content>
          Each property is prepared for a determined number of occupants. It is
          not permitted in any event to exceed the maximum occupancy of the
          property as advertised. It is the responsibility of the client at all
          times to ensure that the number occupying the property matches the
          number of people on the reservation form.
          <br />
          Failure to comply with this rule will result in eviction or the
          payment of an occupancy supplement.
        </Content>
        <Title title="14) Pets" />
        <Content>
          The majority of properties do not allow pets. Only with the express
          authorisation of Book Ibiza and the owner of the property and with
          prior agreement at the time of booking will pets be permitted to stay
          at the property. There could be an extra final cleaning charge for
          housing a pet either indoors or in the garden. If it is brought to our
          attention that a pet is being kept in the rental property without
          prior agreement this could result in the cancellation of the contract
          and eviction from the property, with the loss of the total cost of the
          booking and the security/damage deposit with no possibility of
          reimbursement.
        </Content>
        <Title title="15) Cancellations" />
        <Content>
          If a cancellation is made on behalf of the client a refund will only
          be given if an alternative booking can be made on the property for the
          said dates. If we are unable to successfully re-book the property the
          deposit will be withheld. We will endeavour to re-book the villa for
          you. In the unlikely event that it is necessary to make an alteration
          to or cancel the holiday accommodation specified, we will inform you
          as soon as possible and if requested we will endeavour to arrange
          alternative holiday accommodation of a similar type and standard and
          in a similar location as that originally requested.
        </Content>
        <Title title="16) Anomalies and deficiencies" />
        <Content>
          Any irregularities found by the client on arrival should be
          communicated as soon as possible and always during the stay to Book
          Ibiza. This will allow the staff to rectify any problems or be
          proactive in organising a change of accommodation, should our service
          staff be certain that the issues cannot be rectified.
          <br />
          Any claim submitted after leaving the property cannot be considered
          because the fundamental criteria for consideration cannot be checked
          in order to proceed with the claim.
        </Content>
        <Title title="17) Building work" />
        <Content>
          The properties are privately owned and are largely situated in
          residential areas. They are not generally situated in areas with large
          tourist complexes. At times private or government building work can be
          taking place in the surrounding area. Neither Book Ibiza nor the
          property owner has any influence over this work and cannot be held
          responsible for building work or any disturbance it may cause.
        </Content>
        <Title title="18) Water, Electricity and Internet" />
        <Content>
          Cuts to the supply of water, electricity or internet are not regular.
          However the local authorities can occasionally cut or limit supplies
          for short periods of time. Neither Book Ibiza nor the property owner
          can take responsibility for any inconvenience caused by temporary cuts
          to supply.
        </Content>
        <Title title="19) Consumption of energy" />
        <Content>
          At certain times of the year rental clients will require extra air
          conditioning or heating. Properties with these facilities often make a
          charge and where this is the case,the higher consumption of energy
          will be reflected in the price of the reservation.
        </Content>
        <Title title="20) Insurance" />
        <Content>
          The rental price does not include insurance, except where it is
          expressly indicated along with the type of insurance included. It is
          recommended that you obtain comprehensive travel insurance for the
          duration of your holiday.
        </Content>
        <Title title="21) Responsibility of the Proprietor" />
        <Content>
          The proprietor will accept no responsibility for loss or theft of the
          rental client or any occupier's property or for injury caused by
          incorrect use of facilities provided in the property.
        </Content>
        <Title title="22) Responsibility of the company" />
        <Content>
          Book Ibiza acts solely as an intermediary between owners, agents and
          rental clients, being responsible only for accurate information
          regarding available properties, facilities and precise location.
          <br />
          The individual terms and conditions for each booking are clearly
          stated in your contract. Please make sure you read these before
          signing and if you have any questions contact Book Ibiza for any
          reassurance you may need before signing and completing your booking.
        </Content>
        <Title title="23) Further information" />
        <Content>
          The individual terms and conditions for each booking are clearly
          stated in your contract. Please make sure you read these before
          signing and if you have any questions contact Ibiza High Life for any
          reassurance you may need before signing and completing your booking.
        </Content>
      </div>
    </div>
  )
}

const Title = ({ title }) => {
  return (
    <strong className="text-sm md:text-2xl capitalize text-stone-700">
      {title}
    </strong>
  )
}

const Content = ({ children }) => {
  return (
    <p className="font-property text-sm pb-2 pl-6 text-stone-600">{children}</p>
  )
}
